const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://moveuplynchburg.org',
    gaTrackingId: null,
    trailingSlash: false,
  },
  header: {
    logo: 'https://resources.moveuplynchburg.org/images/moveup-svg-logo.svg',
    logoLink: 'https://moveuplynchburg.org',
    title:"Policies and Agreements",
    githubUrl: '',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: false,
      indexName: '',
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      // add trailing slash if enabled above
      
    ],
    collapsedNav: [
      '/move-up-user-agreement',
      '/move-up-driver-agreement', 
      '/move-up-acceptable-use-policy', // add trailing slash if enabled above
      '/move-up-driver-eligability-policy'
    ],
    links: [{ text: 'MoveUP', link: 'https://moveuplynchburg.org' }],
    frontLine: false,
    ignoreIndex: true,
    title:
      "Policies",
  },
  siteMetadata: {
    title: 'Policies and Agreements | MoveUP',
    description: 'Policies and agreements for MoveUP.',
    ogImage: null,
    docsLocation: '#',
    favicon: 'https://cdn.moveuplynchburg.org/images/favicon-32x32.png',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'MoveUP Policies and Agreements',
      short_name: 'MoveUP Polcies',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
       {
            "src": "/android-chrome-192x192.png",
            "sizes": "192x192",
            "type": "image/png"
        },
        {
            "src": "/android-chrome-384x384.png",
            "sizes": "384x384",
            "type": "image/png"
        }
      ],
    },
  },
};

module.exports = config;
